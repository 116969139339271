export const APP_STATUS = "production";
// local
export const LOCAL_BACKEND_URL = "http://localhost:8080";
export const LOCAL_STRAPI_BACKEND_URL = "http://localhost:1337";
export const LOCAL_API_URL = "http://localhost:8080/api";
export const LOCAL_STRAPI_API_URL = "http://localhost:1337/api";
export const LOCAL_GRAPHQL_URL = "http://localhost:1337/graphql";
export const LOCAL_DOMAIN_URL = "http://localhost:3000";

// production
export const PRODUCTION_BACKEND_URL = "https://omsbe.aamax.co";
export const PRODUCTION_STRAPI_BACKEND_URL = "https://admin.aamax.co";
export const PRODUCTION_API_URL = "https://omsbe.aamax.co/api";
export const PRODUCTION_STRAPI_API_URL = "https://admin.aamax.co/api";
export const PRODUCTIOL_GRAPHQL_URL = "https://admin.aamax.co/graphql";
export const PRODUCTION_DOMAIN_URL = "https://app.aamax.co";

// others

export const cloudName = "dmkou0hmb";
export const uploadPreset = "amsimages";
export const stripePubKey =
  "pk_live_51Oe2mxE1ylVs7bzf7xw2g8TPSsEqZXaYN1RPDKAEYvoRQMngzDOwW880ToXNHG5hOnggv5UVl36YaxFgJUGecdQO00ELJjXggf";


